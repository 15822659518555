import axios from "axios";
import Fuse from "fuse.js";
import { max, min } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Carousel, IconButton, Input, InputGroup, Loader } from "rsuite";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow_back.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as EnergyProgramSavingIcon } from "../../../assets/icons/energy_program_saving.svg";
import { ReactComponent as HotelClass1Icon } from "../../../assets/icons/hotel_class-1.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tCertifiedHotel } from "../../../models/certifiedHotel";
import { COLORS } from "../../../utils/colors";
import { getCountryInfo } from "../../../utils/countries";
import { getErrorMessage } from "../../../utils/httpResponses";
import Flex from "../../Flex";
import Icon from "../../Icon";
import PageBottomPadding from "../../PageBottomPadding";
import InterTag from "../../Text/Inter";
import HotelModal from "./hotelModal";
import { tipologyDetails } from "./utils";

interface iHotelCardProps {
  hotelItem: tCertifiedHotel;
  onOpenModal(hotelItem: tCertifiedHotel): void;
}

const HotelCard: React.FC<iHotelCardProps> = ({ hotelItem, onOpenModal }) => {
  const { trans } = useLocalizationState();
  const [activeIndex, setActiveIndex] = useState(0);

  const {
    name,
    district,
    country,
    county,
    classification,
    tipology,
    certifications,
    id,
    images,
    recommended,
  } = hotelItem;

  const countryInfo = getCountryInfo(country)!;

  const tipologyInfo = tipologyDetails[tipology];
  return (
    <Flex
      key={id}
      column
      onClick={() => {
        onOpenModal(hotelItem);
      }}
      color={COLORS.white}
      style={{ borderRadius: "8px" }}
    >
      <div style={{ position: "relative" }} onClick={(e) => e.stopPropagation()}>
        {recommended && (
          <div
            className="carousel-recommended"
            style={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}
          >
            <Flex
              className="carousel-recommended"
              middle
              gap={4}
              color={COLORS.primary_100}
              style={{ padding: "4px 8px", borderRadius: "6px" }}
            >
              <InterTag
                className="carousel-recommended"
                text={trans("Recommended")}
                color={COLORS.primary_900}
                size={14}
              />
            </Flex>
          </div>
        )}
        <div style={{ position: "relative" }}>
          <Flex
            style={{
              position: "absolute",
              zIndex: 1,
              height: "100%",
              left: 4,
              ...(activeIndex === 0 ? { display: "none" } : {}),
            }}
            center
            middle
          >
            <IconButton
              disabled={activeIndex === 0}
              appearance="subtle"
              size="xs"
              circle
              icon={<Icon Element={ArrowBackIcon} size={20} fill={COLORS.white} />}
              onClick={() => setActiveIndex((prev) => max([0, prev - 1]) as number)}
            />
          </Flex>
          <Flex
            style={{
              position: "absolute",
              zIndex: 1,
              height: "100%",
              right: 4,
              ...(activeIndex + 1 === images.length ? { display: "none" } : {}),
            }}
            center
            middle
          >
            <IconButton
              disabled={activeIndex + 1 === images.length}
              appearance="subtle"
              size="xs"
              circle
              icon={<Icon Element={ArrowForwardIcon} size={20} fill={COLORS.white} />}
              onClick={() => setActiveIndex((prev) => min([images.length - 1, prev + 1]) as number)}
            />
          </Flex>
          <Carousel
            onSelect={setActiveIndex}
            activeIndex={activeIndex}
            shape="bar"
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            {images.map((i) => (
              <img
                key={i}
                style={{
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
                src={i}
                alt={i}
              />
            ))}
          </Carousel>
        </div>
      </div>
      <Flex
        column
        gap={16}
        style={{
          padding: "16px",
          cursor: "pointer",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
        className="hover-bg-gray-200"
      >
        <Flex column>
          <Flex row between middle>
            <InterTag
              size={24}
              text={name}
              color={COLORS.secondary}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            />
            <Icon Element={ArrowForwardIcon} size={20} fill={COLORS.secondary} />
          </Flex>
          <InterTag
            text={[county, district, countryInfo.name].filter((v) => v).join(", ")}
            size={14}
            color={COLORS.gray_400}
          />
        </Flex>
        <Flex one row between gap={16}>
          <Flex one>
            {tipologyInfo ? (
              <Flex row middle gap={4}>
                <Icon Element={tipologyInfo.icon} fill={COLORS.secondary} size={16} />
                <InterTag size={14} color={COLORS.secondary} text={trans(tipologyInfo.label)} />
              </Flex>
            ) : (
              <div />
            )}
          </Flex>
          <Flex one>
            {classification > 0 ? (
              <Flex one row middle gap={4}>
                <InterTag size={14} color={COLORS.secondary} text={classification} />
                <Icon Element={HotelClass1Icon} size={16} fill={COLORS.secondary} />
              </Flex>
            ) : (
              <div />
            )}
          </Flex>
          <Flex one right>
            {certifications.length > 0 ? (
              <Flex
                middle
                gap={4}
                color={COLORS.primary_100}
                style={{ padding: "4px 8px", borderRadius: "6px" }}
              >
                <Icon Element={EnergyProgramSavingIcon} fill={COLORS.primary_900} size={16} />
                <InterTag text={trans("Certified")} color={COLORS.primary_900} size={14} />
              </Flex>
            ) : (
              <div />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ExplorePageContent: React.FC = () => {
  const { trans } = useLocalizationState();
  const [searchText, setSearchText] = useState("");
  const [modalState, setModalState] = useState<
    { open: true; hotelItem: tCertifiedHotel } | { open: false; hotelItem: null }
  >({ open: false, hotelItem: null });
  const { resolve, reject, pending, data, isLoading, isResolved } = useGetRequest<{
    hotels: tCertifiedHotel[];
  }>({
    hotels: [],
  });
  const [filteredHotels, setFilteredHotels] = useState<tCertifiedHotel[]>([]);
  const [fuseObj, setFuseObj] = useState<Fuse<tCertifiedHotel>>();

  useEffectSafe(() => {
    axios
      .get("https://422cet7px37d2jhuu33rjmh3gy0psmwg.lambda-url.eu-west-1.on.aws/")
      .then((res) => {
        const {
          data: { hotels },
        } = res;
        resolve({ hotels });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        reject(error, true);
      });
  }, [resolve, reject, trans, pending]);
  useEffect(() => {
    setFilteredHotels(data.hotels);
    setFuseObj(
      new Fuse(data.hotels, {
        keys: ["name", "address", "country", "district", "county"],
        includeMatches: true,
        ignoreFieldNorm: true,
      })
    );
  }, [data.hotels]);

  const hotelsRendered = useMemo(() => {
    return filteredHotels.map((hotelItem) => {
      return (
        <HotelCard
          key={hotelItem.id}
          hotelItem={hotelItem}
          onOpenModal={(hotelItem) => setModalState({ open: true, hotelItem })}
        />
      );
    });
  }, [filteredHotels]);

  const onSearch = () => {
    if (!searchText) {
      setFilteredHotels(data.hotels);
    } else if (fuseObj) {
      const fuseHotels = fuseObj.search(searchText);
      setFilteredHotels(fuseHotels.map((fh) => fh.item));
    }
  };

  return (
    <Fragment>
      <HotelModal
        open={modalState.open}
        onClose={() => setModalState({ open: false, hotelItem: null })}
        hotelItem={modalState.hotelItem}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 3,
          top: 0,
          width: "100%",
          maxWidth: "500px",
          backgroundColor: COLORS.white,
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Flex center color={COLORS.white} style={{ padding: "8px 0" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSearch();
            }}
            style={{ width: "80%" }}
          >
            <InputGroup size="md">
              <InputGroup.Button style={{ backgroundColor: COLORS.light_white }} onClick={onSearch}>
                <Icon Element={SearchIcon} fill={COLORS.secondary} size={24} />
              </InputGroup.Button>
              <Input
                style={{ backgroundColor: COLORS.light_white }}
                onChange={setSearchText}
                value={searchText}
                placeholder={trans("Planning your next accommodation?")}
              />
            </InputGroup>
            <input type="submit" style={{ display: "none" }} />
          </form>
        </Flex>
      </div>
      {isLoading ? (
        <Flex center middle style={{ height: "100%" }}>
          <Loader size="lg" />
        </Flex>
      ) : isResolved ? (
        <Flex column gap={24} style={{ padding: "0 16px", marginTop: "70px" }}>
          {hotelsRendered}
          <PageBottomPadding size={50} />
        </Flex>
      ) : null}
    </Fragment>
  );
};

export default ExplorePageContent;
