import moment from "moment";
import { tStay } from "../models/reservation";

export const defaultStay: tStay = {
  _id: "",
  additionalGuests: [],
  awards: [],
  canceled: false,
  checkIn: moment().toDate(),
  checkOut: moment().toDate(),
  code: "",
  consumption: {
    co2: 0,
    costs: 0,
    lastUpdated: moment().toDate(),
    measures: {},
  },
  createdBy: "",
  endAt: moment().toDate(),
  hotel: "",
  mainGuest: { _id: "", email: "", name: "" },
  numberOfGuests: 0,
  occupancyStatus: "NA",
  room: "",
  startAt: moment().toDate(),
};
